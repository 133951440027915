<template>
  <div>
    <b-modal
      :title="$t('Gestão de marcadores')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="showModal"
      :ok-only="(((requestError=== true) || (form_data.length === 0)) ? true : false)"
      :ok-title="(((requestError=== true) || (form_data.length === 0)) ? $t('Fechar') : $t('Guardar'))"
      :ok-variant="(((requestError=== true) || (form_data.length === 0)) ? 'outline-secondary' : 'primary')"
      no-close-on-esc
      no-close-on-backdrop
      @ok="saveChangeBookmarks"
      @hidden="hiddenModal"
    >
      <b-row>
        <!-- Mostra mensagem de aviso-->
        <b-col cols="12">
          <b-alert
            v-if="messageAlertForm"
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              {{ $t('Atenção') }}
            </h4>
            <div class="alert-body">
              <span>{{ messageAlertForm }}</span>
            </div>
          </b-alert>
        </b-col>

        <!-- Marcadores -->
        <b-col
          v-if="form_data.length > 0"
          cols="12"
        >
          <validation-observer
            ref="formBookmarksManager"
            #default="{}"
          >
            <draggable
              v-model="form_data"
              :class="{['cursor-grabbing']: dragging === true}"
              tag="app-collapse"
              handle=".handle"
              :component-data="collapseComponentData"
              @start="dragging = true; collapsedAllBookmark()"
              @end="dragging = false"
              @change="collapsedAllBookmark"
            >
              <app-collapse-item
                v-for="(bookmark, index) in form_data"
                :key="`bookmark-item-drag-${index}`"
                ref="bookmarkCollapseItem"
                title=""
              >
                <template #header>
                  <div class="row collapse-title d-inline w-100 text-truncate">
                    <div
                      class="d-inline  mr-75 ml-75 handle"
                    >
                      <feather-icon
                        icon="MenuIcon"
                        :size="sizeIconsAction"
                        :title="$t('Mover marcador')"
                      />
                    </div>
                    <div
                      class="d-inline mr-75 ml-75"
                    >
                      <feather-icon
                        v-b-tooltip
                        icon="BookmarkIcon"
                        :size="sizeIconsAction"
                        :title="`${bookmark.sw075s03}`"
                        :style="{color: bookmark.sw075s04}"
                      />
                    </div>
                    <div
                      class="d-inline mr-75 ml-75"
                    >
                      {{ bookmark.sw075s03 }}
                    </div>
                  </div>
                </template>

                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Cor')"
                      :label-for="`bookmark-color-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="providerColor"
                        rules="required"
                        mode="eager"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-input-group-prepend is-text>
                            <color-picker
                              ref="colorpicker"
                              :value="bookmark.sw075s04"
                              type="chrome"
                              @click.stop.prevent="openFromPicker"
                              @change="updateFromPicker($event, index)"
                            />
                          </b-input-group-prepend>
                          <b-form-input
                            :id="`bookmark-color-${index}`"
                            v-model="bookmark.sw075s04"
                            v-mask="tokenMaskHexColor"
                            placeholder=""
                            :class="errors.length > 0 ? 'is-invalid':null"
                            @blur="$refs.providerColor[index].validate()"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Nome')"
                      :label-for="`bookmark-name-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          :id="`bookmark-name-${index}`"
                          v-model="bookmark.sw075s03"
                          placeholder=""
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Limite')"
                      :label-for="`bookmark-limit-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="providerLimit"
                        rules="required|greaterThanZero"
                      >
                        <b-form-input
                          :id="`bookmark-limit-${index}`"
                          v-model="bookmark.sw075s05"
                          v-mask="tokenMaskNumber"
                          placeholder=""
                          type="tel"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          @blur="$refs.providerLimit[index].validate()"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="cargosLeader.length > 0"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('Cargos')"
                      :label-for="`bookmark-cargos-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <v-select
                        :id="`bookmark-cargos-${index}`"
                        :key="`bookmark-cargos-${index}-${bookmark.sw104s02.join('-')}`"
                        :ref="`bookmarkCargos${index}`"
                        v-model="bookmark.sw104s02"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="cargosLeader"
                        :multiple="true"
                        :value="bookmark.sw104s02"
                        label="sw067s02"
                        item-text="sw067s02"
                        item-value="sw067s01"
                        class="limitHeightSelect"
                        @input="setValueCargoByBookmark($event, index)"
                        @change="setValueCargoByBookmark($event, index)"
                        @search:blur="clearSearchCargoByBookmark(index)"
                      >
                        <template #option="{ sw067s02 }">
                          {{ sw067s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Nenhum cargo') }}
                        </div>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="mt-1"
                      @click.stop.prevent="removeBookmark(index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Eliminar') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </draggable>
          </validation-observer>
        </b-col>

        <!-- Bloco sem registos -->
        <b-col
          v-if="form_data.length === 0"
          cols="12"
        >
          <b-card
            no-body
            class="shadow-none mb-0"
          >
            <b-card-body>
              <b-card-text>{{ $t('Nenhum marcador disponível') }}</b-card-text>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Acao -->
        <b-col
          v-if="requestError===false"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2"
            @click.stop.prevent="addNewBookmark"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('Novo marcador') }}</span>
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, VBModal, VBTooltip, BFormInput, BAlert, BRow, BCol, BFormGroup, BButton, BInputGroup, BInputGroupPrepend, BCard, BCardBody, BCardText,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import { Keyboard } from '@capacitor/keyboard'
import { onUnmounted } from '@vue/composition-api'
import bookmarksModule from '@store-modules/bookmarks'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { greaterThanZero } from '@validations-custom'
import Ripple from 'vue-ripple-directive'
import { VueColorpicker } from 'vue-colorpicker-pop'
import { mask } from 'vue-the-mask'
import Vue from 'vue'
import { showModalDeleteMessage } from '@core/utils/utils'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import modalError from './modalError.vue'

Vue.component('app-collapse', AppCollapse)

export default {
  components: {
    BModal,
    draggable,
    BFormInput,
    vSelect,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    AppCollapseItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    'color-picker': VueColorpicker,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  props: {
  },
  data() {
    return {
      greaterThanZero,
      dragging: false,
      showModal: false,
      collapseComponentData: {
        props: {
          accordion: true,
        },
      },
      tokenMaskHexColor: {
        mask: '#XXXXXX',
        tokens: {
          X: {
            pattern: /[0-9a-zA-Z]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
      tokenMaskNumber: {
        mask: '######',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('bookmarks', ['bookmarks', 'cargosLeader', 'colors', 'permsColors', 'messageAlertForm', 'form_data', 'requestError']),
    ...mapActions('crm_leads', ['updateListviewBookmarks']),
    form_data: {
      get() {
        return this.$store.getters['bookmarks/form_data']
      },
      set(value) {
        this.$store.commit('bookmarks/setUpdateFormData', value)
      },
    },
  },
  async created() {
    try {
      await store.dispatch('bookmarks/getAllBookmarksEdition').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.showModal = true
      this.$root.$emit('app::loading', false)
    } catch (err) {
      //
    }
  },
  methods: {
    async showMsgDetailRequest(pType, pMessage, pDetail) {
      this.$swal({
        title: '',
        icon: pType || '',
        html: '<div id="modal-error-bookmark" />',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: this.$t('Fechar'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        willOpen: () => {
          const ComponentClass = Vue.extend(modalError)
          this.vueModalErrorInstance = new ComponentClass({
            propsData: {
              itens: pDetail,
              type: pType,
              message: pMessage,
            },
            i18n: this.$i18n,
            store: this.$store,
            methods: {
              t: (...args) => this.$t(...args),
              tc: (...args) => this.$tc(...args),
            },
          })
          this.vueModalErrorInstance.$mount()
          document.getElementById('modal-error-bookmark').appendChild(this.vueModalErrorInstance.$el)
        },
      }).then(() => {
        this.vueModalErrorInstance.$destroy()
        this.vueModalErrorInstance = null
      })
    },
    hiddenModal() {
      this.$parent.showBookmarksModalEdition = false
    },
    setValueCargoByBookmark(selectedValues, index) {
      this.form_data[index].sw104s02 = selectedValues
      this.$store.commit('bookmarks/setUpdateFormData', this.form_data)
    },
    openFromPicker() {
      if (store.state.capacitor.isNative === true) {
        Keyboard.hide()
      }
    },
    updateFromPicker(color, index) {
      let colorHex = ''
      if (color !== '') {
        const rgb2hex = c => `#${c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, 0)).join``}`
        colorHex = rgb2hex(color)
      }

      this.form_data[index].sw075s04 = colorHex
    },
    addNewBookmark() {
      this.$store.dispatch('bookmarks/createNewBookmark').then(() => {
        const oCreateNewBookmark = this.$refs.bookmarkCollapseItem[this.$refs.bookmarkCollapseItem.length - 1]
        oCreateNewBookmark.updateVisible(true)
      })
    },
    async removeBookmark(index) {
      const self = this

      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, this.$t('Pretende eliminar este marcador?'))
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          self.removeBookmarkConfirm(index, 1)
        }
      })
    },
    async removeBookmarkConfirm(index, pCheckBeforeDelete) {
      const self = this

      self.$root.$emit('app::loading', true)
      await store.dispatch('bookmarks/removeBookmark', {
        index_bookmark: index,
        bookmark: this.form_data[index],
        checkBeforeDelete: pCheckBeforeDelete,
      }).then(res => {
        self.$root.$emit('app::loading', false)
        if (res.error === 0) {
          self.collapsedAllBookmark()
          this.$swal({
            icon: 'success',
            title: res.title,
            text: res.message,
            confirmButtonText: this.$t('Fechar'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          // Atualiza os marcadores na listagem
          store.dispatch('crm_leads/updateListviewBookmarks').catch(error => {
            this.showMsgErrorRequest(error)
          })
        } else if ((res.msg_detail !== undefined) && (res.msg_detail.length > 0)) {
          this.$swal({
            title: this.$t('Atenção'),
            icon: 'error',
            html: '<div id="modal-error-bookmark-delete" />',
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Eliminar'),
            canceluttonText: this.$t('Cancelar'),
            customClass: {
              confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-primary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
            willOpen: () => {
              const ComponentClass = Vue.extend(modalError)
              this.vueModalErrorInstance = new ComponentClass({
                propsData: {
                  itens: res.msg_detail,
                  type: 'delete',
                },
                i18n: this.$i18n,
                store: this.$store,
                methods: {
                  t: (...args) => this.$t(...args),
                  tc: (...args) => this.$tc(...args),
                },
              })
              this.vueModalErrorInstance.$mount()
              document.getElementById('modal-error-bookmark-delete').appendChild(this.vueModalErrorInstance.$el)
            },
          }).then(resultConfirm => {
            this.vueModalErrorInstance.$destroy()
            this.vueModalErrorInstance = null

            if (resultConfirm.value) {
              self.removeBookmarkConfirm(index, 0)
            }
          })
        } else if (res.message) {
          this.$swal({
            icon: 'error',
            title: res.title,
            text: res.message,
            confirmButtonText: this.$t('Fechar'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      }).catch(error => {
        self.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    collapsedAllBookmark() {
      if (this.$refs.bookmarkCollapseItem !== undefined) {
        this.$refs.bookmarkCollapseItem.forEach(elm => {
          elm.updateVisible(false)
        })
      }
    },
    clearSearchCargoByBookmark(index) {
      this.$refs[`bookmarkCargos${index}`][0].search = ''
    },
    async saveChangeBookmarks(e) {
      if (this.requestError === false) {
        e.preventDefault()
        const self = this

        if (this.form_data.length === 0) {
          this.hiddenModal()
        } else {
          await this.$refs.formBookmarksManager.validate().then(success => {
            if (success) {
              self.$root.$emit('app::loading', true)
              store.dispatch('bookmarks/saveChangeBookmarks').then(res => {
                self.$root.$emit('app::loading', false)

                // Caso tenha informação detalhada
                if ((res.msg_detail !== undefined) && (res.msg_detail.length > 0)) {
                  if (res.error === 0) {
                    this.showMsgDetailRequest('success', res.message, res.msg_detail)
                  } else {
                    this.showMsgDetailRequest('error', res.message, res.msg_detail)
                  }
                } else if (res.error === 0) {
                  this.showMsgSuccessRequest({ message: res.message })
                } else {
                  this.showMsgErrorRequest({ message: res.message })
                }

                self.hiddenModal()

                // Atualiza os marcadores na listagem
                if (res.error === 0) {
                  store.dispatch('crm_leads/updateListviewBookmarks').catch(error => {
                    this.showMsgErrorRequest(error)
                  })
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              }).catch(error => {
                this.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
            }
          })
        }
      }
    },
  },
  setup() {
    const BOOKMARKS_MODULE_NAME = 'bookmarks'

    if (!store.hasModule(BOOKMARKS_MODULE_NAME)) {
      store.registerModule(BOOKMARKS_MODULE_NAME, bookmarksModule)

      onUnmounted(() => {
        if (store.hasModule(BOOKMARKS_MODULE_NAME)) store.unregisterModule(BOOKMARKS_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/core/mixins/main-menu-mixin';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/include';

.limitHeightSelect ::v-deep {
  .vs__dropdown-menu{
    max-height: 175px!important;
  }
}

.cursor-grabbing ::v-deep{
  .card{
    .card-header{
      cursor: -webkit-grabbing!important;
      cursor: grabbing!important;
    }
  }
}

.vue-colorpicker{
  padding:0!important;
  border:0!important;
  border-radius: 0!important;
  height: inherit !important;
}

.vue-colorpicker ::v-deep{
  .vue-colorpicker-btn{
    border:1px solid #d8d6de!important;
  }
}

@include media-breakpoint-down(xs) {
  .v-select ::v-deep{
    .vs__selected{
      width: 100%!important;
    }
  }
}
</style>
