<template>
  <div>

    <h4
      v-if="type==='success' && message !==''"
      class="mb-1"
    >
      {{ message }}
    </h4>

    <h4
      v-if="type==='error'"
      class="mb-1"
    >
      {{ t('Atenção') }} - {{ t('Leads associados a marcadores') }}
    </h4>
    <p
      v-if="type==='error'"
      class="text-left"
    >
      {{ t('Problema ao alterar o limite de Leads dos seguintes marcadores:') }}
    </p>

    <dl
      v-if="itens.length > 0"
      class="text-left"
    >
      <dd
        v-for="(row, index) in itens"
        :key="index"
      >
        <i18n
          tag="p"
          class="font-small-4"
          :path="row.txt"
        >
          <template v-slot:marcador>
            <feather-icon
              v-b-tooltip
              icon="BookmarkIcon"
              :size="sizeIconsAction"
              :title="`${row.name}`"
              :style="{color: row.color}"
            />
          </template>

          <template v-slot:total>
            <b>{{ row.total }}</b>
          </template>

          <template v-slot:limite>
            <b>{{ row.limit }}</b>
          </template>

        </i18n>
      </dd>
    </dl>

  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    itens: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  created() {
    if (this.itens.length > 0) {
      const messages = []

      this.itens.forEach(row => {
        messages[row.txt] = row.txt
      })

      this.$i18n.mergeLocaleMessage(this.$i18n.locale, messages)
    }
  },
}
</script>
