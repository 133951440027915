var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.$t('Gestão de marcadores'),"cancel-title":_vm.$t('Fechar'),"cancel-variant":"outline-secondary","size":"lg","body-class":"","visible":_vm.showModal,"ok-only":(((_vm.requestError=== true) || (_vm.form_data.length === 0)) ? true : false),"ok-title":(((_vm.requestError=== true) || (_vm.form_data.length === 0)) ? _vm.$t('Fechar') : _vm.$t('Guardar')),"ok-variant":(((_vm.requestError=== true) || (_vm.form_data.length === 0)) ? 'outline-secondary' : 'primary'),"no-close-on-esc":"","no-close-on-backdrop":""},on:{"ok":_vm.saveChangeBookmarks,"hidden":_vm.hiddenModal}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.messageAlertForm)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('Atenção'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messageAlertForm))])])]):_vm._e()],1),(_vm.form_data.length > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"formBookmarksManager",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;
return [_c('draggable',{class:( _obj = {}, _obj['cursor-grabbing'] = _vm.dragging === true, _obj ),attrs:{"tag":"app-collapse","handle":".handle","component-data":_vm.collapseComponentData},on:{"start":function($event){_vm.dragging = true; _vm.collapsedAllBookmark()},"end":function($event){_vm.dragging = false},"change":_vm.collapsedAllBookmark},model:{value:(_vm.form_data),callback:function ($$v) {_vm.form_data=$$v},expression:"form_data"}},_vm._l((_vm.form_data),function(bookmark,index){return _c('app-collapse-item',{key:("bookmark-item-drag-" + index),ref:"bookmarkCollapseItem",refInFor:true,attrs:{"title":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row collapse-title d-inline w-100 text-truncate"},[_c('div',{staticClass:"d-inline  mr-75 ml-75 handle"},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":_vm.sizeIconsAction,"title":_vm.$t('Mover marcador')}})],1),_c('div',{staticClass:"d-inline mr-75 ml-75"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],style:({color: bookmark.sw075s04}),attrs:{"icon":"BookmarkIcon","size":_vm.sizeIconsAction,"title":("" + (bookmark.sw075s03))}})],1),_c('div',{staticClass:"d-inline mr-75 ml-75"},[_vm._v(" "+_vm._s(bookmark.sw075s03)+" ")])])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-md-right text-sm-left pd-75",attrs:{"label":_vm.$t('Cor'),"label-for":("bookmark-color-" + index),"label-cols-md":"2"}},[_c('validation-provider',{ref:"providerColor",refInFor:true,attrs:{"rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('color-picker',{ref:"colorpicker",refInFor:true,attrs:{"value":bookmark.sw075s04,"type":"chrome"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openFromPicker($event)},"change":function($event){return _vm.updateFromPicker($event, index)}}})],1),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskHexColor),expression:"tokenMaskHexColor"}],class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":("bookmark-color-" + index),"placeholder":""},on:{"blur":function($event){return _vm.$refs.providerColor[index].validate()}},model:{value:(bookmark.sw075s04),callback:function ($$v) {_vm.$set(bookmark, "sw075s04", $$v)},expression:"bookmark.sw075s04"}})],1)]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-md-right text-sm-left pd-75",attrs:{"label":_vm.$t('Nome'),"label-for":("bookmark-name-" + index),"label-cols-md":"2"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":("bookmark-name-" + index),"placeholder":""},model:{value:(bookmark.sw075s03),callback:function ($$v) {_vm.$set(bookmark, "sw075s03", $$v)},expression:"bookmark.sw075s03"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-md-right text-sm-left pd-75",attrs:{"label":_vm.$t('Limite'),"label-for":("bookmark-limit-" + index),"label-cols-md":"2"}},[_c('validation-provider',{ref:"providerLimit",refInFor:true,attrs:{"rules":"required|greaterThanZero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tokenMaskNumber),expression:"tokenMaskNumber"}],class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":("bookmark-limit-" + index),"placeholder":"","type":"tel"},on:{"blur":function($event){return _vm.$refs.providerLimit[index].validate()}},model:{value:(bookmark.sw075s05),callback:function ($$v) {_vm.$set(bookmark, "sw075s05", $$v)},expression:"bookmark.sw075s05"}})]}}],null,true)})],1)],1),(_vm.cargosLeader.length > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-md-right text-sm-left pd-75",attrs:{"label":_vm.$t('Cargos'),"label-for":("bookmark-cargos-" + index),"label-cols-md":"2"}},[_c('v-select',{key:("bookmark-cargos-" + index + "-" + (bookmark.sw104s02.join('-'))),ref:("bookmarkCargos" + index),refInFor:true,staticClass:"limitHeightSelect",attrs:{"id":("bookmark-cargos-" + index),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cargosLeader,"multiple":true,"value":bookmark.sw104s02,"label":"sw067s02","item-text":"sw067s02","item-value":"sw067s01"},on:{"input":function($event){return _vm.setValueCargoByBookmark($event, index)},"change":function($event){return _vm.setValueCargoByBookmark($event, index)},"search:blur":function($event){return _vm.clearSearchCargoByBookmark(index)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var sw067s02 = ref.sw067s02;
return [_vm._v(" "+_vm._s(sw067s02)+" ")]}}],null,true),model:{value:(bookmark.sw104s02),callback:function ($$v) {_vm.$set(bookmark, "sw104s02", $$v)},expression:"bookmark.sw104s02"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum cargo'))+" ")])])],1)],1):_vm._e(),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeBookmark(index)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Eliminar')))])],1)],1)],1)],1)}),1)]}}],null,false,3832425835)})],1):_vm._e(),(_vm.form_data.length === 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"shadow-none mb-0",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-card-text',[_vm._v(_vm._s(_vm.$t('Nenhum marcador disponível')))])],1)],1)],1):_vm._e(),(_vm.requestError===false)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addNewBookmark($event)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Novo marcador')))])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }