import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    bookmarks: state => state.bookmarks.value,
    cargosLeader: state => state.cargosLeader.value,
    colors: state => state.colors.value,
    permsColors: state => state.permsColors.value,
    messageAlertForm: state => state.messageAlertForm.value,
    form_data: state => state.form_data.value,
    requestError: state => state.requestError.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setBookmarks(state, payload) {
      state.bookmarks.value = []

      if (Array.isArray(payload)) {
        payload.forEach(bookmark => {
          state.bookmarks.value.push(bookmark)
        })
      }
    },
    setCargosLeader(state, payload) {
      state.cargosLeader.value = []

      if (Array.isArray(payload)) {
        payload.forEach(cargo => {
          state.cargosLeader.value.push(cargo)
        })
      }
    },
    setColors(state, payload) {
      state.colors.value = []

      if (Array.isArray(payload)) {
        payload.forEach(color => {
          state.colors.value.push(color)
        })
      }
    },
    setPermsColors(state, payload) {
      state.permsColors.value = payload || {}
    },
    setModuleView(state, payload) {
      state.moduleView.value = payload
    },
    setMessageAlertForm(state, payload) {
      state.messageAlertForm.value = payload
    },
    setFormDataBookmarks(state) {
      state.form_data.value = []

      if (Array.isArray(state.bookmarks.value)) {
        state.bookmarks.value.forEach(bookmark => {
          const newBookmark = bookmark
          newBookmark.sw104s02 = []

          if (bookmark.sw075s01 in state.permsColors.value) {
            const permsColorsSelect = state.permsColors.value[bookmark.sw075s01]

            if (permsColorsSelect.length > 0) {
              const permsColorsSelectUnique = permsColorsSelect.filter((elem, pos, arr) => arr.indexOf(elem) === pos)

              permsColorsSelectUnique.forEach(permsColorsID => {
                const aInfoPerm = state.cargosLeader.value.filter(oCargo => oCargo.sw067s01 === permsColorsID)
                if (aInfoPerm.length > 0) {
                  newBookmark.sw104s02.push(aInfoPerm[0])
                }
              })
            }
          }

          state.form_data.value.push(newBookmark)
        })
      }
    },
    setUpdateFormData(state, payload) {
      const newData = Array.from(payload)
      state.form_data.value = newData
    },
    createNewBookmark(state) {
      const sTempItem = `new_${new Date().getTime()}_${state.form_data.value.length}`
      const newBookmark = {
        sw075s01: sTempItem,
        sw075s03: '',
        sw075s04: '',
        sw075s05: '',
        sw104s02: state.cargosLeader.value,
        new: true,
      }

      state.form_data.value.push(newBookmark)
    },
    removeBookmark(state, payload) {
      if (state.form_data.value !== undefined) {
        if (payload in state.form_data.value) {
          state.form_data.value.splice(payload, 1)
        }
      }
    },
    setRequestError(state, payload) {
      state.requestError.value = payload
    },
  },
  actions: {
    // Obter os marcadores para editar
    async getAllBookmarksEdition({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/formGestBookmarks`)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                commit('setRequestError', false)
                commit('setBookmarks', response.data.data.bookmarks)
                commit('setCargosLeader', response.data.data.cargosLeader)
                commit('setColors', response.data.data.colors)
                commit('setPermsColors', response.data.data.permsColors)
                commit('setMessageAlertForm', '')

                if (response.data.data.moduleView !== undefined) {
                  commit('setModuleView', response.data.data.moduleView)
                }
                if (response.data.data.msgAlertUser !== undefined) {
                  commit('setMessageAlertForm', response.data.data.msgAlertUser)
                }

                commit('setFormDataBookmarks')
                resolve(true)
              } else {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os marcadores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setRequestError', true)
              reject(new Error(i18n.t('Problema ao carregar os marcadores')))
            }
          })
      })
    },

    // Guarda as alterações da informação dos marcadores
    async saveChangeBookmarks({ state }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw075s12', '')
        formData.append('moduleView', state.moduleView.value)

        state.form_data.value.forEach(bookmark => {
          let sw075s01 = ''
          if (bookmark.sw075s01) {
            sw075s01 = btoa(bookmark.sw075s01)
          }

          formData.append(`sw075s01[${sw075s01}]`, sw075s01)
          formData.append(`sw075s03[${sw075s01}]`, bookmark.sw075s03)
          formData.append(`sw075s04[${sw075s01}]`, bookmark.sw075s04)
          formData.append(`sw075s05[${sw075s01}]`, bookmark.sw075s05)

          if (bookmark.sw104s02 !== undefined) {
            bookmark.sw104s02.forEach(sw067 => {
              formData.append(`sw104s02[${sw075s01}][]`, sw067.sw067s01)
            })
          }
        })

        let prefixUrl = 'leads'
        if (state.moduleView.value) {
          prefixUrl = state.moduleView.value
        }

        api.post(`${apiConfig.url_base_api}${prefixUrl}/saveFormGestBookmarks`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if (response.data.error === 0) {
                resolve({ error: 0, message: response.data.msg, msg_detail: response.data.msg_detail })
              } else {
                resolve({ error: 1, message: response.data.msg, msg_detail: response.data.msg_detail })
              }
            } else {
              reject(new Error(i18n.t('Problema ao guardar os marcadores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar os marcadores')))
            }
          })
      })
    },

    // Eliminar o marcador
    async removeBookmark({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const { index_bookmark, bookmark, checkBeforeDelete } = payload

        if (('new' in bookmark) && (bookmark.new === true)) {
          commit('removeBookmark', index_bookmark)
          resolve({ error: 0, title: '', message: i18n.t('Marcador eliminado com sucesso') })
        } else {
          const formData = new FormData()
          formData.append('sw075s01', btoa(bookmark.sw075s01))
          formData.append('checkBeforeDelete', checkBeforeDelete)

          let prefixUrl = 'leads'
          if (state.moduleView.value) {
            prefixUrl = state.moduleView.value
          }

          api.post(`${apiConfig.url_base_api}${prefixUrl}/removeBookmark`, formData)
            .then(response => {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  commit('removeBookmark', index_bookmark)
                  resolve({ error: 0, title: '', message: i18n.t('Marcador eliminado com sucesso') })
                } else {
                  resolve({
                    error: 1, title: '', message: response.data.msg, msg_detail: response.data.msg_detail,
                  })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar o marcador')))
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar o marcador')))
              }
            })
        }
      })
    },

    // Adiciona novo marcador
    async createNewBookmark({ commit }) {
      return new Promise(resolve => {
        commit('createNewBookmark')
        resolve(true)
      })
    },

  },
}
